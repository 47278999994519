import type {
  INotifyItem,
  TNotifyItemType,
} from '~/plugins/notify/types/notify'

export const useNotify = (
  type: TNotifyItemType,
  content: string,
  options?: Partial<INotifyItem>,
) => {
  const { $notify } = useNuxtApp()

  return $notify.show({
    ...options,
    type,
    content,
  })
}
